import React from 'react'
import { Link } from 'gatsby'

const features = [
    {
      title: 'Import and Upload',
      description: 'Easily import and upload various document formats such as PDFs, Word documents, and images with drag-and-drop support.',
    },
    {
      title: 'Document Classification',
      description: 'Automatically classify documents into types like manuals, reports, and contracts, or manually tag and categorize them.',
    },
    {
      title: 'Search and Retrieval',
      description: 'Utilize powerful search capabilities with natural language processing and advanced filters for precise document retrieval.',
    },
    {
      title: 'Document Summarization',
      description: 'Automatically generate concise document summaries and provide users the option to customize or edit them as needed.',
    },
    {
      title: 'Document Version Control',
      description: 'Keep track of document revisions, compare different versions, and maintain a detailed audit trail of changes.',
    },
    {
      title: 'Language Translation',
      description: 'Translate documents into multiple languages for global collaboration and communication.',
    },
    {
      title: 'Collaboration and Sharing',
      description: 'Foster collaboration by enabling document sharing, collaborative annotation, and real-time commenting among team members.',
    },
    {
      title: 'Compliance Monitoring',
      description: 'Automate compliance checks, receive alerts for violations, and ensure adherence to industry standards and regulations.',
    },
    {
      title: 'Document Creation Assistance',
      description: 'Leverage AI-powered content generation to assist in report and document creation, including suggesting templates and structures.',
    },
    {
      title: 'Training and Onboarding',
      description: 'Create AI-guided tutorials, tailored onboarding materials, and learning resources to facilitate user training and onboarding.',
    },
  ];

const Features = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
           <div className="project-start-area ptb-50">
                <div className="container">
                    <div className="section-title">
                        <h2>NeoGPT Features</h2>
                        <p>What can NeoGPT do with your documents?</p>    
                    </div>
                    <div className="document-ingestion">
                        <div className="card-slider">
                            {features.map((feature, index) => (
                            <div className="card" key={index}>
                                <h3>{feature.title}</h3>
                                <p>{feature.description}</p>
                            </div>
                        ))}
                        </div>
                    </div>  
                </div>
            </div>
        </section>
    )
}

export default Features
