import React from 'react'
import { Link } from 'gatsby'
import shape from '../../../assets/images/shape/circle-shape1.png'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const Capabilities = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
           <div className="project-start-area ptb-25">
                <div className="container">
                    <div className="section-title">
                        <h2>Types of Documents</h2>
                        <p>What can NeoGPT do with your documents?</p>    
                    </div>
                    <div className="faq-accordion">
                        <Accordion allowZeroExpanded preExpanded={['a']}>
                            <AccordionItem uuid="a">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Technical Manuals
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Summarizing lengthy technical manuals for equipment, machinery, or processes to provide a quick reference for engineers and technicians
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="b">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Research Papers
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Condensing research papers and studies in fields relevant to heavy industry to extract key findings and insights.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="c">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Reports
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Summarizing project reports, engineering analysis reports, and financial reports to highlight important findings, conclusions, and recommendations.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="d">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Regulatory Documents
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Summarizing complex regulatory documents, such as safety standards, environmental regulations, and compliance guidelines.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="e">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Meeting Minutes
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Providing concise summaries of meeting minutes from engineering meetings and discussions.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="f">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Design Documents
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing design documents, including blueprints, schematics, and CAD drawings, to emphasize critical design elements.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="g">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Safety Procedures
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing safety manuals and procedures to highlight essential safety protocols and guidelines for personnel.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="h">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Training Materials
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Creating summarized versions of training manuals and materials to facilitate quick learning and onboarding for new employees.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="i">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Project Plans
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Condensing project plans and schedules to provide an overview of project milestones and deliverables.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="j">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Contracts
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing legal contracts and agreements to highlight key terms, obligations, and deadlines.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="k">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Patents
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing patent documents to provide a clear understanding of innovative technologies and their applications.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="l">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Maintenance Manuals
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing maintenance manuals for machinery and equipment to ensure proper upkeep and repairs.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="m">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Environmental Impact Assessments
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing environmental impact assessment reports for compliance and sustainability purposes.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="n">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Quality Assurance Documents
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing quality control manuals and documents to ensure product quality and consistency
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="o">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Engineering Specifications
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing technical specifications for materials, components, and products to facilitate procurement and manufacturing processes.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="p">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Health and Safety Guidelines
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing health and safety guidelines for workplace safety and accident prevention.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="q">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Project Proposals
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing project proposals and bids for review and decision-making purposes.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="r">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Literature
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing scientific literature related to heavy industry, including academic research and case studies.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            
                            <AccordionItem uuid="s">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Financial Statements
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing financial statements, including balance sheets, income statements, and cash flow statements, for financial analysis.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem uuid="t">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Troubleshooting Guides
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Summarizing troubleshooting guides for diagnosing and resolving technical issues.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>

                <div className="circle-shape1">
                    <img src={shape} alt="project" />
                </div>
        </section>
    )
}

export default Capabilities
