import React from 'react'
import { Link } from 'gatsby'
import projectStart from '../../../assets/images/NeoGPT.png'

const Banner = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
           <div className="project-start-area ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="project-start-image">
                                <img src={projectStart} alt="project" onContextMenu={(e) => e.preventDefault()} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="project-start-content">
                                <h2>AI-Powered Document Management</h2>
                                <p>NeoGPT addresses challenges in onboarding and training by creating personalized materials, simplifies daily work with document aids and compliance checks, promotes collaboration with sharing features, assists in document creation and maintenance, supports continuous learning, and ensures knowledge transfer for career progression and institutional knowledge preservation. </p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-web"></i> 
                                    Request A Demo
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
