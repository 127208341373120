import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Banner from "../../components/AiAgents/NeoGPT/Banner"
import Capabilities from "../../components/AiAgents/NeoGPT/Capabilities"
import Features from "../../components/AiAgents/NeoGPT/Features"
import ProjectStartArea from "../../components/Common/StartProject"
import Footer from "../../components/App/Footer"

const NeoGPT = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="NeoGPT" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="AI-Powered Document Management" 
            />
            <Banner />
            <Features/>
            <Capabilities />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default NeoGPT